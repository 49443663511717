import { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../layout/Defaultlayout';
import {
  deleteInvitedUsers,
  editInvitedUserStatus,
  getInvitedUsers,
  guideTourComplete,
  InviteUserByEmail,
  InviteUserCsv,
  reinviteUser,
} from '../api/Api';
import { useSelector } from 'react-redux';
import { selectSubscription, selectUser } from '../store/slice/Userslice';
import Papa from 'papaparse';
import MyIcon from '../components/cardeditor/icons/Myicon';
import Select from 'react-select';
import Pagination from '../components/pagination/Pagination';
import Loader from '../common/loader/Index';
import CustomLoader from '../common/loader/Customloader';
import { FaSearch } from 'react-icons/fa';
import { useTour } from '../Authcontext';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useNavigate } from 'react-router-dom';

interface invitedUsersDataProps {
  id: any;
  length: any;
  first_name: string;
  last_name: string;
  email: string;
  user_role: string;
  profile_picture: any;
  country: {
    key: string;
    label: string;
  };
  status: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '1.5px 0px',
    borderColor: '#ffff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffff',
    },
    fontSize: '14px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#ccc' : state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transperant',
      // color: '#000',
    },
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '14px',
    zIndex: 9999,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const Users = () => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const userDetails = useSelector(selectUser); //get user data from the redux
  const userData = userDetails?.user;
  const companyName = userDetails?.user.company_name;
  const [loading, setLoading] = useState(true);
  const [csvInviteUserLoading, setCsvInviteUserLoading] = useState(false);
  const [adduserVisible, setAdduserVisible] = useState(false);
  const [inviteByEmailVisible, setInviteByEmailVisible] = useState(false);
  const [inviteEmailUser, setInviteEmailUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: companyName,
  });
  const [inviteLoading, setInviteLoading] = useState(false);
  const [inviteError, setInviteError] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [inviteSendError, setInviteSendError] = useState('');
  const [inviteSuccessMessage, setInviteSuccessMessage] = useState('');
  const [invitedUsersData, setInvitedUsersData] = useState<
    invitedUsersDataProps[]
  >([]);
  const [userLimitVisible, setUserLimitVisible] = useState<boolean>(false);
  const [suceesVisible, setSuccessVisible] = useState(false);
  const [suceesVisibleMessage, setSuccessVisibleMessage] = useState('');
  // const [imageSrc, setImageSrc] = useState('profile.jpg');
  const [inviteUserImageSrc, setInviteUserImageSrc] = useState('profile.jpg');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<invitedUsersDataProps[]>(
    [],
  );
  const [editUser, setEditUser] = useState<invitedUsersDataProps>();
  const [editVisible, setEditVisible] = useState(false);
  const [editUserLoading, setEditUserLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]); // Store selected user IDs
  const [deleteUserVisible, setDeleteUserVisible] = useState(false);
  const [userIdsToDelete, setUserIdsToDelete] = useState<number[]>([]); // Store user IDs for deletion
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>('');
  const [activeUserCount, setActiveUserCount] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    GetInvitedUsers();
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      const newImageSrcs: any = {};
      for (const user of invitedUsersData) {
        const profilePictureUrl = `${apiUrl}/public/cards/images/${user.profile_picture}`;
        const exists = await checkImageExists(profilePictureUrl);
        newImageSrcs[user.id] = exists ? user.profile_picture : 'profile.jpg';
      }
      setInviteUserImageSrc(newImageSrcs);
    };

    if (invitedUsersData?.length > 0) {
      loadImages();
    }
  }, [invitedUsersData]);

  useEffect(() => {
    const filterUsers = invitedUsersData.filter((user) => {
      const matchesSearchQuery =
        `${user.first_name} ${user.last_name} ${user.email} ${user.user_role} ${user.status}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      const matchesStatusFilter = statusFilter
        ? user.status === statusFilter
        : true;
      return matchesSearchQuery && matchesStatusFilter;
    });
    setFilteredUsers(filterUsers);
  }, [searchQuery, statusFilter, invitedUsersData]);

  const handleStatusChange = (selectedOption: any) => {
    setStatusFilter(selectedOption ? selectedOption.value : '');
  };

  const handleAddusers = () => {
    setAdduserVisible(!adduserVisible);
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setAdduserVisible(false);
    }
  };

  useEffect(() => {
    if (adduserVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [adduserVisible]);

  const GetInvitedUsers = async () => {
    try {
      const response = await getInvitedUsers();

      if (response?.data.success) {
        const invitedUsers = response.data.invitedUsers.map((user: any) => ({
          id: user.id,
          first_name: user.user_first_name || user.first_name,
          last_name: user.user_last_name || user.last_name,
          email: user.email,
          user_role: user.user_role,
          status: user.status,
          profile_picture: user.profile_picture,
        }));

        // Include userData from Redux into the invited users data
        if (userData) {
          invitedUsers.push({
            id: userData.id,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            user_role: userData.user_role,
            status: 'active',
            profile_picture: userData.profile_picture,
          });
        }

        // Count active users
        const activeUserCount = invitedUsers.filter(
          (user: any) => user.status === 'active',
        ).length;
        setActiveUserCount(activeUserCount);
        // Sort users by status, showing active users first
        invitedUsers.sort(
          (a: invitedUsersDataProps, b: invitedUsersDataProps) => {
            if (a.status === 'active' && b.status !== 'active') return -1;
            if (a.status !== 'active' && b.status === 'active') return 1;
            return 0;
          },
        );

        setInvitedUsersData(invitedUsers);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching invited users:', error);
    }
  };

  const subscriptionDetails = useSelector(selectSubscription); //get sunscription data from the redux

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInviteSendError('');

    let errorMessage = '';
    switch (name) {
      case 'firstName':
        if (!value.trim()) {
          errorMessage = 'First name is required';
        } else if (!/^[a-zA-Z ]*$/.test(value)) {
          errorMessage = 'Only letters are allowed';
        } else if (value.trim().length < 2) {
          errorMessage = 'First name must have 2 characters';
        }
        break;
      case 'lastName':
        if (!value.trim()) {
          errorMessage = 'Last name is required';
        } else if (!/^[a-zA-Z ]*$/.test(value)) {
          errorMessage = 'Only letters are allowed';
        } else if (value.trim().length < 1) {
          errorMessage = 'Last name must have 1 characters';
        }
        break;
      case 'email':
        if (!value.trim()) {
          errorMessage = 'Email is required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            String(value).toLowerCase(),
          )
        ) {
          errorMessage = 'Email is not valid';
        }
        break;
      default:
        break;
    }
    setInviteError((prevError) => ({
      ...prevError,
      [name]: errorMessage,
    }));
    setInviteEmailUser((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleInvite = async () => {
    setInviteSendError('');
    if (inviteEmailUser.firstName == '') {
      setInviteError((prevError) => ({
        ...prevError,
        firstName: 'First name is required',
      }));
    }

    if (inviteEmailUser.lastName == '') {
      setInviteError((prevError) => ({
        ...prevError,
        lastName: 'Last name is required',
      }));
    }

    if (inviteEmailUser.email == '') {
      setInviteError((prevError) => ({
        ...prevError,
        email: 'Emai is required',
      }));
    }

    if (
      inviteEmailUser.firstName === '' ||
      inviteEmailUser.lastName === '' ||
      inviteEmailUser.email === '' ||
      inviteError.firstName !== '' ||
      inviteError.lastName !== '' ||
      inviteError.email !== ''
    ) {
      return;
    }
    if (
      subscriptionDetails?.user_limit === null ||
      subscriptionDetails?.user_limit === invitedUsersData?.length
    ) {
      setInviteSendError('Sorry! You have reached limit');
      return;
    }

    try {
      setInviteLoading(true);
      const response = await InviteUserByEmail(inviteEmailUser);
      if (response?.data.success) {
        setInviteSuccessMessage(response.data.message);
        setInviteEmailUser((pre) => ({
          ...pre,
          firstName: '',
          lastName: '',
          email: '',
        }));
        setInviteSendError('');
        GetInvitedUsers();
        setAdduserVisible(false);
        setInviteByEmailVisible(false);
        setSuccessVisible(true);
        setSuccessVisibleMessage('Invitation sent successfully');
      } else {
        setInviteSuccessMessage('');
        setInviteSendError(response?.data.error);
      }
    } catch (error) {
      console.error('Failed to send invitation:', error);
      setInviteSuccessMessage('');
      setInviteSendError('Failed to send invitation. Please try again later.');
    } finally {
      setInviteLoading(false);
    }
  };

  const handleCancelInviteEmailVisible = () => {
    setInviteByEmailVisible(false);
    setInviteEmailUser((pre) => ({
      ...pre,
      firstName: '',
      lastName: '',
      email: '',
    }));
    setInviteSendError('');
    setInviteError({
      firstName: '',
      lastName: '',
      email: '',
    });
    setInviteSuccessMessage('');
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: async (result: any) => {
          // Check for required headers
          // const headers = result.meta.fields;
          const headers = result.meta.fields.map((header: string) =>
            header.toLowerCase(),
          );
          if (
            !headers.includes('first name') ||
            !headers.includes('last name') ||
            !headers.includes('email')
          ) {
            setAlertVisible(true);
            setAlertMsg(
              'Invalid CSV file format. You can download a sample CSV to see the required format.',
            );
            return;
          }
          const headerMap: any = {};
          result.meta.fields.forEach((header: string) => {
            const lowerHeader = header.trim().toLowerCase();
            if (lowerHeader === 'first name') headerMap['first name'] = header;
            if (lowerHeader === 'last name') headerMap['last name'] = header;
            if (lowerHeader === 'email') headerMap['email'] = header;
          });

          const users = result.data.map((user: any) => ({
            firstName: user[headerMap['first name']] || '',
            lastName: user[headerMap['last name']] || '',
            email: user[headerMap['email']] || '',
          }));
          const userLimit = subscriptionDetails?.user_limit - 1;
          setAdduserVisible(!adduserVisible);
          setCsvInviteUserLoading(true);

          try {
            setCsvInviteUserLoading(true);
            const response = await InviteUserCsv({
              users,
              userLimit,
              companyName,
            });

            if (response?.data.success) {
              setCsvInviteUserLoading(false);
              setAdduserVisible(false);
              const successUser = response.data.successfulInvitations;
              const failedUsers = response?.data.failedInvitations;
              if (successUser.length > 0) {
                setSuccessVisible(true);
              }
              setSuccessVisibleMessage('Invitations set successfully');
              GetInvitedUsers(); // Refresh the invited users list
              if (failedUsers.length > 0) {
                const failedEmails = failedUsers
                  .map((user: any) => user.email)
                  .join(', ');
                setTimeout(() => {
                  setAlertVisible(true);
                  setAlertMsg(
                    `Some invitations failed. This may be because the email already exists or you reached your users limit ${failedEmails}`,
                  );
                  setSuccessVisible(false);
                }, 1000);
              }
            } else {
              setCsvInviteUserLoading(false);
              const failedUser = response?.data.failedInvitations;
              const failedEmails = failedUser
                .map((user: any) => user.email)
                .join(', ');
              setAlertVisible(true);
              setAlertMsg(
                `Some invitations failed. This may be because the email already exists or you reached your users limit ${failedEmails}`,
              );
            }
          } catch (error) {
            setCsvInviteUserLoading(false);
            console.error('Failed to send invitations:', error);
          }
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };

  const checkImageExists = async (url: any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  /* Dropdown filter start */
  const EditAction = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const Status = [
    { value: '', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'pending', label: 'Pending' },
    { value: 'expired', label: 'Expired' },
  ];

  /* Pagination start */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust this number as needed

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStatusClassNames = (status: string) => {
    switch (status) {
      case 'expired':
        return 'bg-red-100 text-red-500';
      case 'pending':
        return 'bg-blue-100 text-blue-500';
      case 'active':
        return 'bg-green-100 text-green-500 ml-2';
      case 'inactive':
        return 'bg-red-100 text-red-500';
      default:
        return '';
    }
  };

  const handleEdit = (user: any) => {
    if (
      user?.status == 'inactive' &&
      activeUserCount == subscriptionDetails?.user_limit
    ) {
      setAlertVisible(true);
      setAlertMsg(
        'You have reached the maximum number of active users. To activate this user, you must deactivate another user first.',
      );
      return;
    }
    setEditVisible(true);
    setEditUser(user);
  };

  const handleEditAlert = () => {
    setAlertVisible(true);
    setAlertMsg(
      'This user has not accepted the invitation. You can either wait for them to accept it or delete the invitation.',
    );
  };

  const handleDeleteUser = (userId: number) => {
    setUserIdsToDelete([userId]);
    setDeleteUserVisible(true);
  };

  const handleBulkDelete = () => {
    // Find active users in the selection
    const activeUsers = selectedUsers.filter((userId) =>
      invitedUsersData.some(
        (user) =>
          user.id === userId &&
          (user.status === 'active' || user.status === 'inactive'),
      ),
    );

    // If there are active users, show an alert and stop further execution
    if (activeUsers.length > 0) {
      setAlertVisible(true);
      setAlertMsg(
        `Active user cannot be deleted you can only active or deactivate. Please select users that are not active`,
      );
      return;
    }

    setUserIdsToDelete(selectedUsers);
    setDeleteUserVisible(true);
  };

  const confirmDeleteUsers = async () => {
    try {
      await Promise.all(
        userIdsToDelete.map((userId) => deleteInvitedUsers(userId)),
      );
      setInvitedUsersData((prev) =>
        prev.filter((user) => !userIdsToDelete.includes(user.id)),
      );
      setSelectedUsers([]); // Clear selection after deletion
      setDeleteUserVisible(false);
      setUserIdsToDelete([]);
    } catch (error) {
      console.error('Error deleting users:', error);
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // setSelectedUsers(paginatedUsers.map((user) => user.id));
      // Filter out users with the role 'CEO' before setting selected users
      setSelectedUsers(
        paginatedUsers
          .filter((user) => user.user_role !== 'CEO')
          .map((user) => user.id),
      );
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId: number, checked: boolean) => {
    setSelectedUsers((prev) =>
      checked ? [...prev, userId] : prev.filter((id) => id !== userId),
    );
  };

  const handleStatusSave = async () => {
    if (editUser) {
      try {
        setEditUserLoading(true);
        const response = await editInvitedUserStatus({
          id: editUser.id,
          status: editUser.status,
        });

        if (response?.data.success) {
          setEditUserLoading(false);
          setEditVisible(false);
          setSuccessVisible(true);
          setSuccessVisibleMessage('User status updated successfully');
          GetInvitedUsers();
        } else {
          setEditUserLoading(false);
          console.error('Failed to update user status');
        }
      } catch (error) {
        setEditUserLoading(false);
        console.error('Error updating user status:', error);
        // Handle error (e.g., show an error message)
      }
    }
  };

  const handleReinviteUser = async () => {
    if (editUser) {
      setEditUserLoading(true);
      try {
        const response = await reinviteUser({ users: [editUser] });
        if (response?.data.success) {
          GetInvitedUsers();
          setEditUserLoading(false);
          setEditVisible(false);
          setSuccessVisible(true);
          setSuccessVisibleMessage('Invitation sent successfully');
          setInviteSendError('');
        } else {
          // Handle case where response is not successful
          setEditUserLoading(false);
          setInviteSendError('Failed to send invitation');
        }
      } catch (error) {
        console.error('Error reinviting user:', error);
        setEditUserLoading(false);
        setInviteSendError('An error occurred while sending the invitation');
      }
    }
  };

  const handleDownloadSampleCSV = () => {
    // Define the sample data
    const sampleData = [
      ['First Name', 'Last Name', 'Email'],
      ['John', 'Doe', 'john.doe@example.com'],
      ['Jane', 'Smith', 'jane.smith@example.com'],
    ];

    // Convert the data to CSV format
    const csvContent = sampleData.map((e) => e.join(',')).join('\n');

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // Create a URL for the Blob and set it as the href attribute
    const url = URL.createObjectURL(blob);

    // Create a download link
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sample.csv');

    // Append the link and trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      // Check if the tour has already been completed
      // const tourCompleted = localStorage.getItem('tourCompleted');
      if (userData.tour_guide == 0 && tourStep === 3) {
        startTour();
      }
    }, 300);
  }, [tourStep, invitedUsersData]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#add-user',
          popover: {
            title: 'Invite users',
            description:
              'Click here to invite new users to your team. Fill in their details to send them an invitation.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#user-filter',
          popover: {
            title: 'Filter',
            description:
              'Use this filter to sort and narrow down your user list. It helps you quickly find specific users based on criteria such as role, status, or date added.',
            side: 'left',
            align: 'start',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#user-filter') {
          onCompleteTour();
        } else {
          // localStorage.setItem('tourCompleted', 'true');
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    setTourStep(4); // Move to step 1 for Page 2
    navigate('/account-billing');
  };
  const customFilter = (option: any, inputValue: string): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };
  return (
    <>
      <DefaultLayout title="">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <div className="flex flex-wrap justify-between items-center">
                <div>
                  <h1 className="text-purpletacx text-xl font-bold">Team</h1>
                  <p className="text-purpletacx font-medium">
                    All members :{' '}
                    {invitedUsersData ? invitedUsersData?.length : 0}
                    {/* {`${
                      invitedUsersData ? invitedUsersData?.length : 0
                    }/${subscriptionDetails?.user_limit}`} */}
                  </p>
                </div>
                <div className="flex gap-2 shrink-0 justify-end items-center relative xl:w-[20%] sm:w-[30%]">
                  {subscriptionDetails?.user_limit === undefined ||
                  subscriptionDetails?.user_limit ===
                    invitedUsersData?.length ||
                  invitedUsersData?.length > subscriptionDetails?.user_limit ? (
                    <>
                      <div
                        onClick={() => setUserLimitVisible(true)}
                        className="bg-[#846c80] text-white text-sm px-5 py-2.5 sm:py-3 rounded-xl cursor-pointer"
                      >
                        <span
                          className={`font-medium  inline-block transition-transform duration-200 ease-in-out ${
                            adduserVisible ? 'rotate-180' : 'rotate-0'
                          }`}
                        >
                          +
                        </span>{' '}
                        Add New User
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        id="add-user"
                        onClick={handleAddusers}
                        className="bg-purpletacx text-white text-sm px-5 py-3 rounded-xl cursor-pointer"
                      >
                        <span
                          className={`font-medium  inline-block transition-transform duration-200 ease-in-out ${
                            adduserVisible ? 'rotate-180' : 'rotate-0'
                          }`}
                        >
                          +
                        </span>{' '}
                        Add New User
                      </div>
                    </>
                  )}

                  {adduserVisible && (
                    <div
                      ref={dropdownRef}
                      className="bg-purpletacx absolute top-15 rounded-lg justify-center items-center p-5 w-[210%] sm:w-full z-50 shadow-sm  slideInFromTop-animate"
                    >
                      <div
                        className="flex cursor-pointer gap-3 items-center mt-2 bg-[#D4D4D4] text-black text-sm font-medium py-2 px-3 rounded-lg "
                        onClick={() => {
                          setInviteByEmailVisible(true);
                          setInviteSuccessMessage('');
                        }}
                      >
                        <MyIcon iconName="emailInvitation" />
                        <p>Email Invitation</p>
                      </div>
                      <div
                        className="flex cursor-pointer gap-3 items-center mt-2 bg-[#D4D4D4] text-black text-sm font-medium py-2 px-2 rounded-lg "
                        onClick={() => {
                          const inputElement = document.getElementById(
                            'csvFileInput',
                          ) as HTMLElement;
                          if (inputElement) {
                            inputElement.click();
                          }
                        }}
                      >
                        <MyIcon iconName="csvInvitation" />
                        <p>Import from csv</p>
                        <input
                          id="csvFileInput"
                          type="file"
                          accept=".csv"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="flex items-center mt-2">
                        <button
                          onClick={handleDownloadSampleCSV}
                          className="text-white hover:underline ml-2 text-sm "
                        >
                          Download Sample CSV
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {editVisible ? (
                <>
                  <div className="bg-white shadow-lg rounded-lg mt-5">
                    <div className="mb-2 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-5 ">
                      <p className="text-black font-medium text-lg">
                        Edit user status
                      </p>
                    </div>
                    <div className=" scrollbar-custom overflow-x-auto animate-fade-in">
                      <table className="min-w-full rounded-lg">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left border-b border-gray whitespace-nowrap tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left border-b border-gray whitespace-nowrap tracking-wider"
                            >
                              Email
                            </th>
                            {/* <th
                              scope="col"
                              className="px-6 py-3 text-left border-b border-gray whitespace-nowrap  tracking-wider"
                            >
                              User Role
                            </th> */}
                            <th
                              scope="col"
                              className="px-6 py-3 text-center border-b border-gray whitespace-nowrap tracking-wider"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-[#59425566]">
                          {editUser ? (
                            <>
                              <tr>
                                <td className="px-6 pl-4 py-4 flex items-center gap-5 whitespace-nowrap text-md text-black font-medium">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    <img
                                      src={`${apiUrl}/public/cards/images/${
                                        inviteUserImageSrc[editUser.id] ??
                                        'profile.jpg'
                                      }`}
                                      alt="user"
                                      className="w-full h-full rounded-full"
                                    />
                                  </div>
                                  <p>{`${editUser.first_name} ${editUser.last_name}`}</p>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-md text-black font-medium">
                                  {editUser.email}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap text-md text-black font-medium">
                                  {editUser.user_role}
                                </td> */}
                                <td className="flex justify-center px-6 py-4 whitespace-nowrap text-md text-black font-medium">
                                  {editUser.status === 'expired' ? (
                                    <>
                                      <span
                                        className={`px-3 rounded-full py-1 text-xs font-semibold ${getStatusClassNames(
                                          editUser?.status,
                                        )}`}
                                      >
                                        {capitalizeFirstLetter(
                                          editUser?.status || '',
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <div className="relative w-25 xl:w-35">
                                        <Select
                                          options={EditAction}
                                          styles={customStyles}
                                          placeholder="Actions"
                                          className="border border-stroke rounded-xl"
                                          onMenuOpen={() => setIsOpen(true)}
                                          onMenuClose={() => setIsOpen(false)}
                                          value={EditAction.find(
                                            (option) =>
                                              option.value === editUser?.status,
                                          )}
                                          onChange={(selectedOption) => {
                                            if (selectedOption) {
                                              setEditUser({
                                                ...editUser,
                                                status: selectedOption.value,
                                              });
                                            }
                                          }}
                                          menuPortalTarget={document.body} // renders the dropdown menu outside
                                          menuPosition="absolute" // makes sure the menu behaves well inside scrollable areas
                                          filterOption={customFilter}
                                        />
                                        <span
                                          className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                                            isOpen ? 'rotate-180' : 'rotate-0'
                                          } `}
                                        >
                                          <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7 10L12 15L17 10H7Z"
                                              fill="#000"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            // ))
                            <tr>
                              <td
                                colSpan={7}
                                className="px-6 py-4 text-center gap-5 whitespace-nowrap text-md text-black font-medium"
                              >
                                No users found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="flex justify-end mt-5 p-4">
                      <div className="w-full sm:w-[40%] md:w-[30%] xl:w-[20%] flex justify-center gap-5">
                        {editUser?.status === 'expired' ? (
                          <>
                            <button
                              className="bg-yellowtacx text-black font-medium w-full py-2 rounded-xl"
                              onClick={handleReinviteUser}
                            >
                              {`${editUserLoading ? 'Sending...' : 'Re-invite'}`}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="bg-yellowtacx text-black font-medium w-full py-2 rounded-xl"
                              onClick={handleStatusSave}
                              disabled={editUserLoading}
                            >
                              {`${editUserLoading ? 'Updating...' : 'Save'}`}
                            </button>
                          </>
                        )}
                        <button
                          className="text-black border w-full py-2 rounded-xl"
                          onClick={() => {
                            setEditVisible(false);
                            setInviteSendError('');
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    {inviteSendError && (
                      <p className="text-red-500 text-center mt-2 text-sm">
                        {inviteSendError}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-white shadow-lg rounded-lg mt-5">
                    <div className="flex flex-col md:flex-row justify-between items-center mb-2 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-5">
                      <div className="bg-gray-200 rounded-lg">
                        <p className="font-medium text-md text-black animate-fade-in">
                          Showing {Math.min(endIndex, totalItems)} of{' '}
                          {totalItems} users
                        </p>
                      </div>
                      <div
                        id="user-filter"
                        className="flex flex-row flex-wrap gap-2"
                      >
                        <div className="relative w-full sm:w-auto">
                          {/* <div className="absolute left-3 top-1/2 -translate-y-1/2">
                            <MyIcon iconName="search" />
                          </div> */}
                          <input
                            type="text"
                            placeholder="Search users"
                            className="w-full rounded-xl border border-stroke bg-white px-3 py-2 pr-10 text-gray-800 text-black focus:outline-none xl:w-60"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        </div>

                        <div className="relative w-40 sm:w-25 xl:w-35">
                          <Select
                            options={Status}
                            styles={customStyles}
                            placeholder="Status"
                            className="border border-stroke rounded-xl"
                            onMenuOpen={() => setIsOpenStatus(true)}
                            onMenuClose={() => setIsOpenStatus(false)}
                            onChange={handleStatusChange}
                            filterOption={customFilter}
                          />
                          <span
                            className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${isOpenStatus ? 'rotate-180' : 'rotate-0'}`}
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                            </svg>
                          </span>
                        </div>

                        <button
                          className={`px-3 xl:px-4 flex gap-2 items-center justify-center rounded-xl text-md bg-red-700 text-white ${selectedUsers.length === 0 ? 'cursor-not-allowed opacity-50' : ''}`}
                          onClick={handleBulkDelete}
                          disabled={selectedUsers.length === 0}
                        >
                          <svg
                            className="w-4 h-4 md:w-5 md:h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="mt-0.5">Bulk Delete</p>
                        </button>
                      </div>
                    </div>

                    <div className="overflow-x-auto animate-fade-in scrollbar-custom rounded-lg shadow-md">
                      <table className="min-w-full  rounded-lg ">
                        <thead>
                          <tr className="hover:bg-gray-50 transition duration-200">
                            <th className="px-6 py-3 text-center border-b border-gray text-sm font-semibold tracking-wider">
                              <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-white border-gray-300 cursor-pointer"
                                checked={
                                  paginatedUsers.length > 1 &&
                                  selectedUsers.length ===
                                    paginatedUsers.length - 1
                                }
                                onChange={handleSelectAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                            >
                              Email
                            </th>
                            {/* <th
                              scope="col"
                              className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                            >
                              User Role
                            </th> */}
                            <th
                              scope="col"
                              className="py-3 px-6 text-center border-b border-gray whitespace-nowrap"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-6 text-center border-b border-gray whitespace-nowrap "
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedUsers?.length > 0 ? (
                            paginatedUsers?.map((user) => (
                              <>
                                <tr
                                  key={user.id}
                                  className={`hover:bg-gray-50 transition duration-200 ${selectedUsers.includes(user.id) ? 'bg-gray-200' : ''}`}
                                >
                                  <td className="px-6 py-4 text-center border-b border-gray">
                                    {user.user_role === 'CEO' ? (
                                      <input
                                        type="checkbox"
                                        className="form-checkbox h-4 w-4 text-gray-300"
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className="form-checkbox h-4 w-4 text-teal-600 border-gray-300 cursor-pointer"
                                        checked={selectedUsers.includes(
                                          user.id,
                                        )}
                                        onChange={(e) =>
                                          handleSelectUser(
                                            user.id,
                                            e.target.checked,
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                  <td className="px-6 py-4 flex items-center border-b border-gray whitespace-nowrap">
                                    <div className="flex-shrink-0 h-12 w-12">
                                      <img
                                        src={`${apiUrl}/public/cards/images/${inviteUserImageSrc[user.id] ?? 'profile.jpg'}`}
                                        alt="user"
                                        className="w-full h-full rounded-full"
                                      />
                                    </div>
                                    <span className="ml-4 text-gray-800 font-medium">{`${user.first_name} ${user.last_name}`}</span>
                                  </td>
                                  <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                                    {user.email}
                                  </td>
                                  {/* <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                                    {user.user_role}
                                  </td> */}
                                  <td className="px-6 py-4 text-center border-b border-gray">
                                    <span
                                      className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusClassNames(user.status)}`}
                                    >
                                      {capitalizeFirstLetter(user.status || '')}
                                    </span>
                                  </td>

                                  <td className="px-6 py-4 text-center border-b border-gray">
                                    {user.user_role === 'CEO' ? (
                                      <span className="text-gray-400">-</span>
                                    ) : (
                                      <div className="flex justify-center gap-2">
                                        <button
                                          className="text-teal-500 transition-transform duration-300"
                                          onClick={() =>
                                            user.status === 'pending'
                                              ? handleEditAlert()
                                              : handleEdit(user)
                                          }
                                        >
                                          <svg
                                            className="w-6 h-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                                            />
                                          </svg>
                                        </button>
                                        {['active', 'inactive'].includes(
                                          user.status,
                                        ) ? (
                                          <button
                                            className="text-red-500 transition-transform duration-300"
                                            onClick={() => {
                                              setAlertVisible(true);
                                              setAlertMsg(
                                                'Active or inactive user cannot be deleted. You can only active or deactivate.',
                                              );
                                            }}
                                          >
                                            <MyIcon
                                              iconName="trash"
                                              color="currentColor"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            className="text-red-500 transition-transform duration-300"
                                            onClick={() =>
                                              handleDeleteUser(user.id)
                                            }
                                          >
                                            <MyIcon
                                              iconName="trash"
                                              color="currentColor"
                                            />
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="py-4 text-center">
                                No users found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {invitedUsersData?.length > 4 && (
                        <div className="mt-4">
                          <Pagination
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            totalItems={totalItems}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {inviteByEmailVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 ">
              <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[80%] md:w-[40%] sm:w-[40%] lg:w-[30%] slideInFromTop-animate">
                <div className="flex flex-row justify-between">
                  <p className="text-purpletacx text-lg font-semibold ">
                    Invite user by email
                  </p>
                  <button onClick={handleCancelInviteEmailVisible}>
                    <svg
                      width="15"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
                {/* <p>How would you like to add the users?</p> */}
                <div className="flex flex-col gap-3 mt-5 mb-4">
                  <div className="flex flex-col ">
                    <label className="text-md text-purpletacx font-medium">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="bg-gray rounded-lg py-1.5 px-2"
                      placeholder="First name"
                      value={inviteEmailUser.firstName}
                      // onChange={(e) => setFirstName(e.target.value)}
                      onChange={handleInputChange}
                    />
                    {inviteError.firstName && (
                      <p className="text-sm text-red-500">
                        {inviteError.firstName}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label className="text-md text-purpletacx font-medium">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="bg-gray rounded-lg py-1.5 px-2"
                      placeholder="Last name"
                      value={inviteEmailUser.lastName}
                      // onChange={(e) => setLastName(e.target.value)}
                      onChange={handleInputChange}
                    />
                    {inviteError.lastName && (
                      <p className="text-sm text-red-500">
                        {inviteError.lastName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="text-md text-purpletacx font-medium">
                      Email
                    </label>
                    <div>
                      <input
                        type="text"
                        name="email"
                        className="bg-gray rounded-lg py-1.5 w-full px-2"
                        placeholder="Email"
                        value={inviteEmailUser.email}
                        // onChange={(e) => setEmail(e.target.value)}
                        onChange={handleInputChange}
                      />
                      {inviteError.email && (
                        <p className="text-sm text-red-500">
                          {inviteError.email}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {inviteSendError && (
                  <p className="text-red-500 text-sm">{inviteSendError}</p>
                )}
                {inviteSuccessMessage && (
                  <p className="text-green-600 text-sm">
                    {inviteSuccessMessage}
                  </p>
                )}
                <div className="mt-5 flex flex-row flex-wrap lg:flex-nowrap gap-4 lg:gap-1 justify-around">
                  <button
                    className="bg-purpletacx w-full lg:w-50 py-1.5  text-white rounded-full"
                    onClick={handleInvite}
                    disabled={inviteLoading}
                  >
                    {inviteLoading ? 'Sending...' : 'Invite'}
                  </button>
                  <button
                    className="border border-purpletacx w-full lg:w-50 py-1.5 text-purpletacx rounded-full"
                    onClick={handleCancelInviteEmailVisible}
                    disabled={inviteLoading}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {suceesVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-purpletacx flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  <MyIcon iconName="success" />
                  <div className="mt-8 text-center text-white">
                    <p className="font-extrabold text-2xl">Successful</p>
                    <p className="text-md">{suceesVisibleMessage}</p>
                  </div>
                  <div
                    className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-[#DFC749] text-black font-bold cursor-pointer"
                    // onClick={onClose}
                    onClick={() => {
                      setSuccessVisible(false);
                      setSuccessVisibleMessage('');
                    }}
                  >
                    Okay!
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {userLimitVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 ">
              <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[90%] md:w-[45%] sm:w-[60%] lg:w-[35%] slideInFromTop-animate">
                <div className="flex flex-row justify-end">
                  <button onClick={() => setUserLimitVisible(false)}>
                    <svg
                      width="15"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-center">
                  <svg
                    className="w-20 h-20 text-red-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="68"
                    height="68"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>

                <p className="text-center text-md font-semibold text-purpletacx mt-2">
                  You have reached your user limits. Upgrade your plan for add
                  more users.
                </p>

                <div className="mt-5">
                  <button
                    className="bg-purpletacx font-bold py-1.5 w-full text-white hover:bg-yellowtacx hover:text-purpletacx rounded-full"
                    onClick={() => setUserLimitVisible(false)}
                    disabled={inviteLoading}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {deleteUserVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-white flex m-auto justify-center rounded-lg relative w-[90%] md:w-[45%] sm:w-[60%] lg:w-[30%] slideInFromTop-animate">
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  <div className="flex justify-center">
                    <svg
                      className="w-20 h-20 text-red-500 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="68"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                  <div className="mt-2 text-center text-black">
                    <p className="font-extrabold text-2xl">Delete user</p>
                    <p className="text-sm">
                      Are you sure you want to delete user?{' '}
                    </p>
                    <p className="text-sm">This action cannot be undone. </p>
                  </div>
                  <div className="gap-2 flex mt-6 flex-wrap sm:flex-nowrap ">
                    <button
                      className="bg-yellowtacx text-black px-10 py-2 rounded-xl w-full"
                      onClick={() => {
                        setDeleteUserVisible(false);
                        setSelectedUsers([]);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-red-700 text-white px-10 py-2 rounded-xl w-full"
                      onClick={confirmDeleteUsers}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {csvInviteUserLoading && <CustomLoader />}

        {alertVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 ">
              <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[90%] md:w-[45%] sm:w-[60%] lg:w-[30%] slideInFromTop-animate">
                <div className="flex flex-row justify-end">
                  <button
                    onClick={() => {
                      setAlertVisible(false);
                      setAlertMsg('');
                    }}
                  >
                    <svg
                      width="15"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-center">
                  <svg
                    className="w-20 h-20 text-red-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="68"
                    height="68"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>

                <p className="text-center text-md font-semibold text-purpletacx mt-2">
                  {alertMsg}
                </p>

                <div className="mt-5">
                  <button
                    className="bg-purpletacx font-bold py-1.5 w-full text-white hover:bg-yellowtacx hover:text-purpletacx rounded-full"
                    onClick={() => {
                      setAlertVisible(false);
                      setAlertMsg('');
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
};

export default Users;
