import { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layout/Defaultlayout';
import { Link, useNavigate } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';
import MyIcon from '../../components/cardeditor/icons/Myicon';
import { QRCodeCanvas } from 'qrcode.react';
import Loader from '../../common/loader/Index';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import { getCardEngagementData, guideTourComplete } from '../../api/Api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import {
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { CiCalendarDate } from 'react-icons/ci';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useTour } from '../../Authcontext';
const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any; isDisabled: boolean }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#A9A9A9' : state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: state.isDisabled ? '#fff' : '#DFC749',
      color: state.isDisabled ? '#A9A9A9' : '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Scrollbar thumb hover color
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
interface EngagementDataProps {
  pageView: number;
  connection: number;
  viewPerUser: any;
  engagementTime: any;
}
const option = [
  { value: '', label: 'Select', isDisabled: true },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'this week', label: 'This week' },
  { value: 'last week', label: 'Last week' },
  { value: 'this month', label: 'This month' },
  { value: 'last month', label: 'Last month' },
  { value: 'custom', label: 'Custom' },
];
const MyProfile = () => {
  const URL = import.meta.env.VITE_API_URL;
  const card_url = import.meta.env.VITE_FRONTEND_URL;
  const userDetails = useSelector(selectUser);
  // const userData = userDetails?.user;
  const [userData, setUserData] = useState(userDetails?.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState('profile.jpg');
  const [isOpen, setIsOpen] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [engagementData, setEngagementData] = useState<EngagementDataProps>();
  const [selectedOption, setSelectedOption] = useState<string>('today');
  const [startDates, setStartDates] = useState(new Date());
  const [endDates, setEndDates] = useState(new Date());
  useEffect(() => {
    setUserData(userDetails?.user);
  }, [userDetails]);
  useEffect(() => {
    const loadImage = async () => {
      if (userData?.profile_picture) {
        const profilePictureUrl = `${URL}/public/cards/images/${userData.profile_picture}`;
        const exists = await checkImageExists(profilePictureUrl);
        if (exists) {
          setImageSrc(userData.profile_picture);
        }
      }
    };
    loadImage();
  }, [userData]);
  useEffect(() => {
    if (userData.card_id && userData.card_id !== null) {
      CardEngagementData(userData.card_id, startDates, endDates);
    }
  }, [userData]);
  const formatTime = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    let timeString = '';
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      // Show minutes if there's an hour or if minutes are non-zero
      timeString += `${minutes}m `;
    }
    // Add remaining seconds if both hours and minutes are 0
    if (hours === 0) {
      timeString += `${remainingSeconds}s`;
    }
    return timeString.trim();
  };
  const CardEngagementData = async (
    userId: any,
    startDates: Date,
    endDates: Date,
  ) => {
    try {
      setLoading(true);
      const response = await getCardEngagementData(userId);
      // Filter data based on selected date range
      const filteredData = response.data.filter((item: any) => {
        const createdAt = new Date(item.updated_at);
        const startDate = new Date(startDates);
        const endDate = new Date(endDates);
        // Normalize dates to start of the day
        const normalizeDate = (date: Date) => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };
        const createdAtNormalized = normalizeDate(createdAt);
        const startDateNormalized = normalizeDate(startDate);
        const endDateNormalized = normalizeDate(endDate);
        // Ensure dates are within the same timezone and compare
        return (
          createdAtNormalized >= startDateNormalized &&
          createdAtNormalized <= endDateNormalized
        );
      });
      // Initialize metrics
      let pageView = 0;
      let totalEngagementTime = 0;
      let totalViews = 0;
      // Process response
      filteredData.forEach((data: any) => {
        pageView += data.view_count;
        totalEngagementTime += data.engagement_time;
        totalViews += 1; // Count each visitor
      });

      // Calculate metrics
      const viewPerUser =
        totalViews > 0 ? (pageView / totalViews).toFixed(2) : 0;
      const engagementTime =
        totalViews > 0 ? (totalEngagementTime / totalViews).toFixed(2) : 0;
      const connection = new Set(
        filteredData.map((entry: any) => entry.visitor_id),
      ).size; //uniq visitore
      // Set state with calculated values
      setEngagementData({
        pageView,
        connection,
        viewPerUser,
        engagementTime,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleDropdownChange = (selected: any) => {
    setSelectedOption(selected.value);
    let startDate = new Date();
    let endDate = new Date();

    switch (selected.value) {
      case 'today':
        startDate = new Date();
        endDate = new Date();
        break;
      case 'yesterday':
        startDate = subDays(new Date(), 1);
        endDate = subDays(new Date(), 1);
        break;
      case 'this week':
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
        endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
        break;
      case 'last week':
        startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        endDate = subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        break;
      case 'this month':
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case 'last month':
        startDate = startOfMonth(subDays(new Date(), 30));
        endDate = endOfMonth(subDays(new Date(), 30));
        break;
      default:
        break;
    }
    setStartDates(startDate);
    setEndDates(endDate);
    if (startDate && endDate) {
      CardEngagementData(userData.card_id, startDate, endDate);
    }
  };
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDates(start);
    setEndDates(end);
    if (start && end) {
      CardEngagementData(userData.card_id, start, end);
    }
  };
  const checkImageExists = async (url: any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };
  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setCopyText(true);
          setTimeout(() => {
            setCopyText(false);
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text to clipboard:', err);
        });
    }
  };
  const secretKey = 2.5;
  const multiplier = 39;
  const offset = 5;
  // Function to encode the ID
  const encodeIds = (id: any) => {
    return (id * multiplier + offset) ^ secretKey;
  };
  const encoded = userData.card_id ? encodeIds(userData.card_id) : null;

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the tour has already been completed
    // const tourCompleted = localStorage.getItem('tourCompleted');
    if (userData.tour_guide == 0 && tourStep === 0) {
      startTour();
    }
  }, [tourStep]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      // allowClose: false,
      doneBtnText: 'Next →',
      // onPopoverRender: (popover) => {
      //   const firstButton = document.createElement("button");
      //   firstButton.innerText = "Skip";
      //   popover.footerButtons.appendChild(firstButton);

      //   firstButton.addEventListener("click", () => {
      //     tour.destroy();
      //   });
      // },
      steps: [
        {
          element: '#support',
          popover: {
            title: 'Support Center',
            description:
              'Access support options and resources by clicking here. Reach out for assistance or view help articles.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#notification',
          popover: {
            title: 'Notifications Panel',
            description:
              'Stay updated with the latest alerts and messages. All your notifications will appear here.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#edit-information',
          popover: {
            title: 'Edit Profile Information',
            description:
              'Click here to update your personal profile details, such as name, email, and other account information.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#my-card',
          popover: {
            title: 'Create New Card',
            description:
              'Start creating your custom cards by clicking here. This will redirect you to the card creation component.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#card-engagement-filter',
          popover: {
            title: 'Engagement Data Filter',
            description:
              'Use these filters to refine and view specific card engagement data.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#card-engagement',
          popover: {
            title: 'Card Engagement Metrics',
            description:
              'Here, you can see detailed engagement metrics for your cards.',
            side: 'bottom',
            align: 'start',
          },
        },
        {
          element: '#card-url',
          popover: {
            title: 'Master Card URL',
            description:
              'This section provides the URL for your master card. You can share or use this link as needed.',
            side: 'bottom',
            align: 'start',
          },
        },
        {
          element: '#card-qr',
          popover: {
            title: 'Master Card QR Code',
            description:
              'Here is the QR code for your master card, which you can scan or share.',
            side: 'left',
            align: 'start',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#card-qr') {
          onCompleteTour();
        } else {
          // localStorage.setItem('tourCompleted', 'true');
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
      // onCloseClick: () => {
      //   localStorage.setItem('tourCompleted', 'true');
      //   tour.destroy();
      // },
      // // Triggered when the last step is deselected, so we can complete the tour
      // onDestroyStarted: (step, options) => {
      //   if (options.element === '#card-notification') {
      //     onCompleteTour();
      //     tour.destroy();
      //   } else {
      //     // localStorage.setItem('tourCompleted', 'true');
      //   }
      // },
    });

    tour.drive();
  };  

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    setTourStep(1); // Move to step 1 for Page 2
    navigate('/my-card'); // Navigate to Page 2
  };

  const customFilter = (option: any, inputValue: string): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };
  return (
    <DefaultLayout title="My Profile">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-row flex-wrap sm:flex-nowrap gap-4">
            <div className=" bg-[#59425533] w-full xl:w-[40%] p-6 h-screen shadow-lg rounded-lg relative overflow-hidden">
              <p className="text-purpletacx font-bold text-lg">
                Contact Information{' '}
              </p>
              <div className="flex flex-col text-center mt-6 gap-5 sm:gap-2 items-center w-full shrink-0">
                <div className="w-[75px] h-[75px] outline outline-white border-white rounded-full">
                  <img
                    src={`${URL}/public/cards/images/${imageSrc}`}
                    alt="user"
                    className="w-full h-full rounded-full"
                  />
                </div>
                <div>
                  <p className="text-purpletacx font-semibold text-lg break-words">
                    {userData?.first_name} {userData?.last_name}
                  </p>
                  <p className="text-black font-semibold text-sm">
                    {userData?.job_title
                      ? JSON.parse(userData?.job_title).length > 0
                        ? JSON.parse(userData?.job_title).join(', ')
                        : ''
                      : ''}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3 mt-10">
                <div>
                  <p className="text-purpletacx font-semibold">Email</p>
                  <p className="text-black font-medium break-words">
                    {userData?.email}
                  </p>
                </div>
                {userData?.phone_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">Cell Phone</p>
                    <p className="text-black font-medium ">
                      {userData?.phone_number}
                    </p>
                  </div>
                )}
                {userData?.office_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">
                      Office Number
                    </p>
                    <p className="text-black font-medium">
                      {userData?.office_number}
                    </p>
                  </div>
                )}
                {userData?.fax_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">Fax Number</p>
                    <p className="text-black font-medium">
                      {userData?.fax_number}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3 justify-center items-center w-full mt-10">
                <Link className="w-full " to={`/my-profile/edit-information`}>
                  <button
                    id="edit-information"
                    className="w-full rounded-full bg-purpletacx text-xs md:text-sm font-semibold  text-white py-2.5 hover:bg-yellowtacx hover:text-[#594255] transition-all duration-200 "
                  >
                    Edit Information
                  </button>
                </Link>
                <Link className="w-full" to={`/my-card`}>
                  <button
                    id="my-card"
                    className="w-full rounded-full bg-yellowtacx text-xs md:text-sm font-semibold  text-purpletacx py-2.5 hover:bg-purpletacx hover:text-white transition-all duration-200"
                  >
                    Edit Card
                  </button>
                </Link>
              </div>
              <span className="absolute bottom-0 left-0">
                <svg
                  width="200"
                  height="137"
                  viewBox="0 0 239 137"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-108.58"
                    y="49.6914"
                    width="322.818"
                    height="195.992"
                    rx="23"
                    transform="rotate(-9.36369 -108.58 49.6914)"
                    fill="#594255"
                    fillOpacity="0.8"
                  />
                </svg>
              </span>
              <span className="absolute bottom-0 left-0">
                <svg
                  width="250"
                  height="103"
                  viewBox="0 0 305 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-21.0293"
                    y="45.7773"
                    width="301.158"
                    height="195.992"
                    rx="23"
                    transform="rotate(-9.36369 -21.0293 45.7773)"
                    fill="#594255"
                    fillOpacity="0.5"
                  />
                </svg>
              </span>
            </div>
            <div className="flex flex-col w-full sm:w-auto h-screen overflow-y-auto scrollbar-custom bg-white shadow-lg p-6 rounded-lg">
              <div className="flex flex-col-reverse sm:flex-row justify-end gap-2 w-full">
                {selectedOption === 'custom' && (
                  <>
                    <div className="mr-5 flex items-center">
                      <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                        <CiCalendarDate
                          style={{
                            color: '#000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </div>
                      <DatePicker
                        selected={startDates}
                        onChange={onChange}
                        startDate={startDates}
                        endDate={endDates}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="border border-[#D8D8D8] w-[115%] sm:w-[111%] text-black px-3 py-[6.5px] rounded-r-[11px]"
                        maxDate={new Date()}
                      />
                    </div>
                  </>
                )}
                <div
                  data-tooltip-id="filter"
                  className="relative w-[53%] sm:w-[40%] md:w-[30%] lg:w-[20%]"
                >
                  <Select
                    options={option}
                    styles={customStyles}
                    onChange={handleDropdownChange}
                    value={option.find(
                      (option) => option.value === selectedOption,
                    )}
                    placeholder="Select"
                    onMenuOpen={() => setIsOpen(true)}
                    onMenuClose={() => setIsOpen(false)}
                    id="card-engagement-filter"
                    filterOption={customFilter}
                  />
                  <span
                    className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    } `}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                    </svg>
                  </span>
                </div>
                <ReactTooltip id="filter" place="left" content="Filter" />
              </div>
              <div
                id="card-engagement"
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-10 gap-3"
              >
                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="pageView" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Page View
                      </p>
                      <p className="text-[#594255ab] text-lg md:text-xl font-bold">
                        {engagementData?.pageView
                          ? engagementData?.pageView
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="connection" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Connections
                      </p>
                      <p className="text-[#594255ab] text-lg md:text-xl font-bold">
                        {engagementData?.connection
                          ? engagementData?.connection
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="viewPerUser" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        View Per User
                      </p>
                      <p className="text-[#594255ab] text-lg md:text-xl font-bold">
                        {engagementData?.viewPerUser
                          ? engagementData?.viewPerUser
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="engagementTime" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Engagement Time
                      </p>
                      <p className="text-[#594255ab] text-lg md:text-xl font-bold">
                        {engagementData?.engagementTime
                          ? formatTime(engagementData?.engagementTime)
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="text-[#59425566] mt-5 " />
              <div className="flex flex-row flex-wrap p-4 justify-between items-center ">
                <div className="xl:w-[60%]">
                  <p className="text-purpletacx text-lg font-semibold">
                    Card URL
                  </p>
                  <p className="text-black text-md font-medium">
                    The card URL is what visitor will see in their browser.
                  </p>
                  <div className="relative">
                    <input
                      id="card-url"
                      ref={inputRef}
                      type="text"
                      name="cardurl"
                      placeholder="link"
                      className="text-xs md:text-sm mt-2 rounded-full w-full border border-stroke bg-white py-2 pl-4 pr-10 text-[#000000] outline-none focus:border-[#DFC749] focus-visible:shadow-none"
                      value={
                        userData.card_id !== null
                          ? `${card_url}/${userData.card_type}-${encoded}`
                          : ''
                      }
                      disabled
                    />
                    {userData.card_id !== null && (
                      <>
                        <button
                          onClick={copyToClipboard}
                          className="absolute right-3 top-4"
                          // title="Copy"
                          data-tooltip-id="Copy"
                        >
                          {copyText ? (
                            <MyIcon iconName="Copied" />
                          ) : (
                            <MyIcon iconName="Copy" />
                          )}
                        </button>
                        <ReactTooltip
                          id="Copy"
                          // place="right"
                          content="Copy URL"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full xl:w-auto mt-10 xl:mt-0 justify-center content-center item-center">
                  <p className="text-center text-xl text-purpletacx font-semibold mb-4">
                    Permalink
                  </p>
                  <div id="card-qr" className="m-auto">
                    <QRCodeCanvas
                      value={
                        userData?.card_id !== null
                          ? `${card_url}/${userData?.card_type}-${encoded}`
                          : ''
                      }
                      size={150}
                      bgColor="#000000"
                      fgColor="#ffffff"
                      level="H"
                    />
                  </div>
                </div>
              </div>
              <hr className="text-[#59425566] mt-5 " />
              <div className="flex flex-row flex-wrap p-4 justify-between items-center ">
                <div className="">
                  <p className="text-purpletacx text-lg font-semibold">
                    Short URL
                  </p>
                  <p className="text-black text-md font-medium">
                    The short URL is a default link to the card and redirect to
                    the URL shown above. As the short URL cannot be changed, it
                    is the recommend link to use if you need to place the link
                    in other web page.{' '}
                  </p>
                  <input
                    type="text"
                    name="cardurl"
                    placeholder="link"
                    className="text-xs md:text-sm mt-2 rounded-full w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-[#DFC749] focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                    value={
                      userData?.card_id !== null
                        ? `${card_url}/${userData?.card_type}-${encoded}`
                        : ''
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};
export default MyProfile;
